import * as React from "react";
import DefaultLayout from "../layouts/default";
import ApplicationContainer from "../containers/apply";
export default () => {
    return (
        <>
            <DefaultLayout title="Apply Now">
                <ApplicationContainer />
            </DefaultLayout>

        </>
    );
};